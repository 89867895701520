export const FORUS = {
  "AITOS_02MP01": "AITOS_02MP01",
  "AITOS_02SIC02": "AITOS_02SIC02",
  "AITOS_02SQ02": "Plunger",
  "AITOS_10GIC01": "Delta heat oil temperature one 7",
  "AITOS_10PDIC02": "Under pressure",
  "AITOS_10TT01": "Oil temperature Zone 01",
  "AITOS_10TT02": "Oil temperature Zone 02",
  "AITOS_10TT03": "Oil temperature Zone 03",
  "AITOS_10TT04": "Oil temperature Zone 04",
  "AITOS_10TT05": "Oil temperature Zone 05",
  "AITOS_10TT06": "Oil temperature Zone 06",
  "AITOS_10TT07": "Oil temperature Zone 07",
  "AITOS_10TT08": "Oil temperature Zone 08",
  "AITOS_10TT09": "Oil temperature Zone 09",
  "AITOS_10TT10": "Oil temperature Zone 10",
  "AITOS_10TT11": "Oil temperature Zone 11",
  "AITOS_10TT12": "Oil temperature Zone 12",
  "AITOS_10TT16": "Temperature primary chamber",
  "AITOS_10TT23": "Temperature secondary chamber",
  "AITOS_20FI01": "Steam flow",
  "AITOS_20TT02": "Superheated steam temp",
  "AITOS_20UI01P": "Steam effect",
  "AITOS_30QT03": "O2 after eco",
  "AITOS_32FQI02": "Total flue gas",
  "AITOS_32FT01": "Fluegas",
  "AITOS_32KV01EM1": "Frequency converter",
  "AITOS_32QT01A": "H2O",
  "AITOS_32QT02": "O2 stack",
  "AITOS_32UIC01": "Total primary air setpoint",
  "AITOS_33FQI03": "Recirculated flue gas",
  "AITOS_43FIC01": "Primary air Zone 1",
  "AITOS_43FIC02": "Primary air Zone 2",
  "AITOS_43FIC03": "Primary air Zone 3",
  "AITOS_43FIC04": "Primary air Zone 4",
  "AITOS_43FIC05": "Primary air Zone 5",
  "AITOS_43FQI15": "Total primary air",
  "AITOS_43FT20": "Secondary air",
  "AITOS_43FT21": "Secondary air",
  "AITOS_47PE03EM1": "Duplex",
  "AITOS_20FT01": "Total steam",
  "AITOS_32TT01": "Fluegas temperature",
  "AITOS_30UQ01": "Fluegas effect",
  "AITOS_20FT10": "Steam drum",
  "AITOS_PlungeTrigger": "Plunger trigger",
  "AITOS_30TT01AYMAX": "AITOS_30TT01AYMAX",
  "AITOS_33KV01EM1_HASTIGHET": "AITOS_33KV01EM1_HASTIGHET"
};