import {
  Box, Button,
  CircularProgress,
  createTheme, FormControl,
  IconButton, InputLabel, MenuItem, Select,
  Snackbar,
  TextField,
  ThemeProvider,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Cookies from "universal-cookie/es6";
import FormTitleText from "../components/FormTitleText";
import { SelectDateTime, Dropdown, Result } from "../components/GenerateGraphComponents";
import CloseIcon from "@mui/icons-material/Close";
import { getGraph } from "../utils/getGraph";
import { FURNACES } from "../utils/data/furnaces";
import { FORUS} from "../utils/data/forus-tags";
import { MK } from "../utils/data/mk-tags"
import { getEngDictionary } from "../utils/getEngDictionary";
import { styled } from "@mui/material/styles";
import app_colors from "../config/app_colors.json";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

import UserService from "../services/user";

const theme = createTheme({
  palette: {
    primary: {
      main: app_colors.primary
    },
    secondary: {
      main: app_colors.secondary
    },
  },
});


const MyTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: app_colors.secondary,
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: app_colors.primary,
      borderWidth: 2
    },
    '&:hover fieldset': {
      borderColor: app_colors.secondary,
    },
    '&.Mui-focused fieldset': {
      borderColor: app_colors.secondary,
    },
  }
});


const MyButton = styled(Button)({
  backgroundColor: app_colors.primary,
  position: "relative",
  display: "inline-block",
  '&:hover': {
    backgroundColor: app_colors.secondary
  },
  marginTop: "4vh"
});



export const GenerateGraph = ({ authenticated }) => {
  const cookies = new Cookies();
  const cookieFVal = cookies.get("dropdownFValue");
  const cookieDates = cookies.get("dates");

  const [furnace, setFurnace] = useState("forus1")
  const [title, setTitle] = useState("");
  const [dropdownFValue, setDropdownFValue] = useState(cookieFVal === undefined ? [] : cookieFVal);
  const [dates, setDates] = useState({fromDate: new Date(), toDate: new Date(), timeInterval: 60});
  if (cookieDates !== undefined) {
    setDates(cookieDates);
  }
  const [snackbarOpts, setSnackbarOpts] = useState(false);
  const [hover, setHover] = useState("#EEEEEE");
  const [isLoading, setIsLoading] = useState(false);
  const [flaskResponse, setFlaskResponse] = useState("");
  const [isResult, setIsResult] = useState(false);
  const [isError, setIsError] = useState(false);
  const [dropdownOptions, setDropdownOptions] = useState();

  let navigate = useNavigate();

  useEffect(() => {
    if (authenticated === false) {
      navigate("/login");
    }
  }, [authenticated, navigate])

  useEffect(() => {
    getEngDictionary()
      .then(data => {
        data.delete("Id");
        data.delete("TimestampUTC");
        data.delete("TimestampLocal");
        data.delete("TimezoneIdentifier");

        setDropdownOptions(data);
      })
      .catch(err => console.error(err));
  })

  const handleDropdownChange = (e) => {
    if ((e.target.value).length > 8) {
      setSnackbarOpts(true);
      return;
    }

    if (flaskResponse.length > 0) {
      setFlaskResponse("");
      setIsResult(false); // Removes the result box if user changes values.
      setIsError(false);
    }
    setDropdownFValue(e.target.value);
    cookies.set("dropdownFValue", e.target.value);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpts(false );
  };

  const handleFormSubmit = async () => {
    setFlaskResponse("");
    setIsResult(false);
    setIsError(false);
    setIsLoading(true);
    //let res = await getGraph(dropdownFValue, dates.fromDate, dates.toDate, title, furnace);
    let res = "";
    await UserService.getGraph(dates.fromDate, dates.toDate, dropdownFValue, title, furnace).then((result) => {
      res = result;
    }).finally((e) => {
      setFlaskResponse(res);
      if (/[0-9a-zA-Z]{8}-[0-9a-zA-Z]{4}-[0-9a-zA-Z]{4}-[0-9a-zA-Z]{4}-[0-9a-zA-Z]{12}(?![0-9a-zA-Z])/.test(res)) {
        setIsResult(true);
      } else {
        setIsError(true);
      }
    })

    setIsLoading(false);
  };

  return (
    <ThemeProvider theme={ theme }>
      <div
        style={{
          margin: "auto",
          width: "75%",
          textAlign: "center",
          paddingTop: "40px",
        }}
      >
        <Box sx={{ minWidth: 120 }}>
          <FormControl fullWidth sx={{ maxWidth: 200 }}>
            <InputLabel id="furnace-select">Furnace</InputLabel>
            <Select
              labelId="furnace-select"
              variant="outlined"
              id="furnace-dropdown"
              value={ furnace }
              label="Furnace"
              onChange={e => {
                if (flaskResponse > 0) {
                  setFlaskResponse(""); // Removes the result box if user changes values.
                  setIsResult(false);
                  setIsError(false);
                }
                setFurnace(e.target.value);
                setDropdownFValue([])
              }}
            >
              {FURNACES.map(({fullName, urlName}) => {
                return <MenuItem value={urlName}>{fullName}</MenuItem>
              })}
            </Select>
          </FormControl>
        </Box>


        <br />

        <MyTextField
          label="Title (optional):"
          id="standard-basic"
          value={ title }
          onChange={(e) => {
            if (flaskResponse > 0) {
              setFlaskResponse(""); // Removes the result box if user changes values.
              setIsResult(false);
              setIsError(false);
            }
            setTitle(e.target.value);
          }}
          variant="outlined"
          color="primary"
          disabled={ isLoading }
        />

        <br />

        <FormTitleText children="Choose up to eight values:" />
        <Dropdown value={ dropdownFValue } handleChange={ handleDropdownChange } dropdownOptions={ furnace === "forus1" ? FORUS : MK } />
        <IconButton
          component="span"
          style={{ marginLeft: "10px", color: hover }}
          onClick={() => {
            if (flaskResponse > 0) {
              setFlaskResponse(""); // Removes the result box if user changes values.
              setIsResult(false);
              setIsError(false);
            }
            cookies.remove("dropdownFValue");
            setDropdownFValue([]);
          }}
          onMouseEnter={ () => setHover("#016612") }
          onMouseLeave={ () => setHover("#000000") }
          disabled={ isLoading }
        >
          <CloseIcon />
        </IconButton>

        <Snackbar
          open={ snackbarOpts }
          autoHideDuration={ 4000 }
          message="Maximum eight values at a time."
          onClose={ handleSnackbarClose }
        />


        <br />

        <SelectDateTime
          value={ dates.fromDate }
          label="From:"
          timezone={ furnace === "forus1" ? "Europe/Oslo" : "UTC" }
          handleChange={(e) => {
            if (flaskResponse > 0) {
              setFlaskResponse(""); // Removes the result box if user changes values.
              setIsResult(false);
              setIsError(false);
            }
            setDates({ ...dates, fromDate: e });
          }}
        />

        <SelectDateTime
          value={ dates.toDate }
          label="To:"
          timezone={ furnace === "forus1" ? "Europe/Oslo" : "UTC" }
          handleChange={(e) => {
            if (flaskResponse > 0) {
              setFlaskResponse(""); // Removes the result box if user changes values.
              setIsResult(false);
              setIsError(false);
            }
            setDates({ ...dates, toDate: e });
          }}
        />

        <br />

        <Button
          onClick={handleFormSubmit}
          variant="contained"
          color="primary"
          sx={{ marginTop: "4vh" }}
        >
          Create plot
        </Button>

        <br />
        {isLoading && (
          <CircularProgress style={{ marginTop: "30px" }} color="primary" />
        )}

        { isResult && <Result filename={flaskResponse} /> }
        { isError && (
          <Typography
            variant="body1"
            gutterBottom
            style={{ marginTop: "20px", color: "#bdbfc2" }}
          >
            { flaskResponse }
          </Typography>
        )}
      </div>
    </ThemeProvider>
  );
};

GenerateGraph.propTypes = {
  authenticated: PropTypes.bool
}
