import { Line } from "react-chartjs-2";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import React from "react";
import { getData } from "../../../utils/Zones/getData";
import { setTrend } from "../../../utils/setTrend";
import { getOptions } from "../../../utils/Zones/getOptions";
import { getEndpoints } from "../../../utils/Zones/getEndpoints";


const OilZone = ({ zone, readings, classes, plotOptions, trends, zonesInSections }) => {
  const readingsExist = Object.keys(readings).length > 0;
  let value, trend, plot;
  if (readingsExist) {
    value = readings['AITOS_10TT' + (zone < 10 ? '0' + zone : zone)][0];
    trend = trends['AITOS_10TT' + (zone < 10 ? '0' + zone : zone)];
    plot = <Line data={getData(zone, trends, readings)}
                 options={getOptions(getEndpoints(true, zonesInSections, readings))}/>;
  } else {
    value = 0;
    trend = 0;
    plot = <></>;
  }


    return (
    <Grid item xs={4}>
      <div style={{ width: '100%', height:'12vh' }}>
        {plot}
      </div>
      <Paper className={classes.paper} elevation={4}>
        <Typography>{ setTrend(trend) }</Typography>
        <Typography><b>Oil { zone }</b></Typography>
        <Typography>{ value }°C</Typography>
      </Paper>
    </Grid>
  )
}

export default OilZone;
