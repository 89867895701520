import { useForm, Controller } from "react-hook-form";
import FormSubmitButton from "../../components/StyledMui/FormSubmitButton";
import TextFieldOutlined from "../../components/StyledMui/TextFieldOutlined";
import { useNavigate, useSearchParams } from "react-router-dom";

import AuthService from "../../services/auth";
import PropTypes from "prop-types";
import { useEffect } from "react";

export const Login = ({ handleLogIn }) => {
  let navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();
  
  const { register, control, handleSubmit, watch, setError, formState: { errors } } = useForm({
    defaultValues: {
      username: "",
      password: ""
    }
  });

  useEffect(() => {
    let paramUsername = searchParams.get("username");
    let paramPassword = searchParams.get("password");

    if (paramUsername && paramPassword) {
      AuthService.login(paramUsername, paramPassword)
        .then(() => {
          handleLogIn();
          navigate("/live");
        })
    }
  }, [handleLogIn, navigate, searchParams]);
  
  const onSubmit = (data, e) => {

    AuthService.login(data.username, data.password)
      .then(() => {
        handleLogIn();
        navigate("/");
      })
      .catch((err) => {
        console.error(err);
      });
  }

  const onError = (errors, e) => {
    console.log(errors);
  }

  return (
    <div style={{ textAlign: "center" }}>
     <form onSubmit={handleSubmit(onSubmit, onError)}>
       <Controller
         render={({ field }) => (
           <TextFieldOutlined
             label="Username"
             placeholder="Username"
             rfhOptional={{ ...field }}
             style={{ marginTop: "20vh" }}
           />
         )}
         name="username"
         control={ control }
         rules={{
           maxLength: {
             value: 15,
             message: "Max length 15."
           },
           required: {
             value: true,
             message: "Username required"
           },
         }}
       />
       <br />
       <Controller
         render={({ field }) => (
           <TextFieldOutlined
             label="Password"
             placeholder="Password"
             type="password"
             rfhOptional={{ ...field }}
             style={{ marginTop: "4vh" }}
           />
         )}
         name="password"
         control={ control }
         rules={{
           minLength: {
             value: 6,
             message: "Minimum 6 characters."
           },
           required: {
             value: true,
             message: "Password required."
           },
         }}
       />
       <br />
       <FormSubmitButton content="Log in" style={{ marginTop: "4vh"}} />
     </form>
    </div>
  );
};

Login.propTypes = {
  handleLogin: PropTypes.func
}