export function getEndpoints(valuesExist, zonesInSections, readings) {
  if (!valuesExist) {
    return [100, 0];
  }
  let max = 0;
  let min = 999999;

  zonesInSections.forEach(({zones}) => {
    for (const z of zones) {
      readings["AITOS_10TT" + (z < 10 ? "0" + z : z)].slice(0, 6).forEach((v) => {
        if (v !== null && v > max) {
          max = v;
        } else if (v !== null && v < min) {
          min = v;
        }
      });
    }
  });
  return [max * 1.02, min * 0.98];
}
