import DateTimePicker from "@mui/lab/DateTimePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns"
import React from "react";
import { Box, TextField } from "@mui/material";



export const SelectDateTime = ({ label, value, handleChange, timezone = "UTC" }) => {
  return (
    <>
      <Box style={{ marginBottom: 10 }}>
        <h1 style={{
          fontFamily: "Roboto",
          fontWeight: 400,
          marginTop: 40,
          color: "#000000",
          marginBottom: -7
        }}>
          { label }
        </h1>
        <span style={{ fontFamily: "Roboto", fontSize: "medium" }}>({ timezone }):</span>
      </Box>

      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DateTimePicker
          ampm={false}
          label={label}
          value={value}
          onChange={handleChange}
          inputFormat="yyyy/MM/dd HH:mm"
          renderInput={props => <TextField {...props} /> }
        />
      </LocalizationProvider>
    </>
  );
};