import React, {useEffect, useState} from 'react';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { range } from "./Functions";
import { Line } from "react-chartjs-2";
import { makeStyles } from "@mui/styles";
import { getEngDictionary } from "../../utils/getEngDictionary";

const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: 16,
      textAlign: 'center',
    },
  }),
);


const colors = ['#FF8AA3', '#8AFF9D', '#96ABFF', '#FFE7A3', '#D88AFF', '#7DF6FF', '#E0FF70', '#FFC8A3', '#FFA3FA'];
const bgColors = ['#A15767', '#57A163', '#5F6CA1', '#A19167', '#8857A1', '#4F9BA1', '#8DA147', '#A17E67', '#A1679E'];

export default function LivePlot({ data }) {
  const classes = useStyles();

  const [ displayNames, setDisplayNames ] = useState({});

  useEffect(() => {
    getEngDictionary()
      .then(data => {
        setDisplayNames(data);
      })
      .catch(err => console.error(err));
  }, []);

  let values = data[0];
  let tags = data[1];

  let valueLength = Object.keys(values).length;

  let valuesExist = valueLength !== 0;
  if (!valuesExist || tags.length === 0) {
    return <></>;
  }


  const endpoints = (data) => {
    if (!valuesExist) {
      return [100, 0];
    }
    let max = 0;
    let min = 999999;
    data.map((n) => function (n) {
      if (n > max) {
        max = n;
      }
      if (n < min) {
        min = n;
      }
    }(n));
    return [max * 1.05, min / 1.05];
  }

  const scales = (datasets) => {
    let sets = {
      'xAxes': {
        display: false
      }
    };
    tags.map(() => {
      let i = Object.keys(sets).length - 1;
      // @ts-ignore
      let endpts = endpoints(datasets[i]['data']);
      // @ts-ignore
      sets['y' + i] = {
        display: true,
        max: endpts[0],
        min: endpts[1],
        grid: {
          drawOnChartArea: false, // only want the grid lines for one axis to show up
        }
      };

    })
    return sets;
  }

  const getDatasets = () => {
    let sets = [];
    tags.map((tag) => function (tag) {
      // @ts-ignore
      sets.push({
        label: displayNames[tag],
        data: values[tag].slice(0, 12).reverse(),
        backgroundColor: bgColors[sets.length],
        borderColor: colors[sets.length],
        yAxisID: 'y' + sets.length
      });
    }(tag))
    return sets;
  }
  let datasets = getDatasets();

  let dts = {
    // @ts-ignore
    labels: range(datasets[0]['data'].length),
    datasets: getDatasets()
  }
  const opt = {
    maintainAspectRatio: false,
    animation: false,
    fill: false,
    scales: scales(datasets),
    plugins: {
      legend: {
        display: true
      }
    },
    elements: {
      line: {
        borderWidth: 3,
        tension: 0.2,
      },
      point: {
        radius: 0,
      }
    },
  }


  return (
    <Grid>
      <Paper className={classes.paper}>
        <div style={{ width: '36vw', height:'60vh' }}>
          <Line data={dts} options={opt}/>
        </div>
      </Paper>
    </Grid>
  )
}
