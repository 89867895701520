import React from "react";


const FormTitleText = ({ children }) => {
  return (
    <h1
      style={{
        fontFamily: "Roboto",
        fontWeight: 400,
        marginBottom: 10,
        marginTop: 40,
        color: "#000000",
      }}
    >
      {children}
    </h1>
  );
};

export default FormTitleText;
