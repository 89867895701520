import { styled } from "@mui/material/styles";
import { Button } from "@mui/material";
import app_colors from "../../config/app_colors.json";
import PropTypes from "prop-types";


const MyButton = styled(Button)({
  backgroundColor: app_colors.primary,
  position: "relative",
  display: "inline-block",
  marginRight: "2vh",
  '&:hover': {
    backgroundColor: app_colors.secondary
  },
});


const MainButton = ({ content } ) => {
  return (
    <MyButton
      variant="contained"
    >
      { content }
    </MyButton>
  )
}

export default MainButton;

MainButton.prototype = {
  content: PropTypes.string.isRequired
}