import StatusBox from "./StatusBox"


const StatusOverview = ({ status }) => {


  return (
    <div>
      {Object.entries(status).map(([key, value]) => {
        return <StatusBox name={ key } status={ value }/>

      })}
    </div>
  );
};

export default StatusOverview;