import React, { useEffect, useState } from 'react';
import AirSection from "./AirSection";
import { getEndpoints } from "../../../utils/Zones/getEndpoints";
import { getOptions } from "../../../utils/Zones/getOptions";
import { makeStyles } from "@mui/styles";


const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: 16,
      textAlign: 'center',
    },
  }),
);


const NewZones = ({ readings, trends }) => {
  const classes = useStyles();

  const [ endpoints, setEndpoints ] = useState([100, 0]);
  useEffect(() => {
    setEndpoints(getEndpoints(valuesExist, zonesInSections, readings))
  }, [readings]);

  const [ plotOptions, setPlotOptions ] = useState({});
  useEffect(() => {
    setPlotOptions(getOptions(endpoints))
  }, [endpoints]);

  const valuesExist = Object.values(readings).length !== 0;
  const zonesInSections = [
    { "section": 1, "zones": [1, 2, 3] },
    { "section": 2, "zones": [4, 5] },
    { "section": 3, "zones": [6, 7] },
    { "section": 4, "zones": [8, 9] },
    { "section": 5, "zones": [10, 11, 12] }
  ];
  const numberOfZones = Object.keys(zonesInSections).length;
  const zoneWidth = ((100 / numberOfZones) - 2) + '%';

  return (
    <div className={classes.root} style={{ width: '98%', padding: '1%', display: "inline-block", margin: 1 }}>
      {zonesInSections.map(({ section, zones }) => (
        <AirSection
          section={ section }
          zones={ zones }
          zoneWidth={ zoneWidth }
          plotOptions={ plotOptions }
          trends={trends}
          valuesExist={ valuesExist }
          readings={ readings }
          classes={ classes }
          zonesInSections={ zonesInSections }
        />
      ))}
    </div>
  );
}

export default NewZones;
