export function getOptions(endpoints) {
  return ({
    maintainAspectRatio: false,
    animation: false,
    fill: true,
    scales: {
      yAxes: {
        display: false,
        max: endpoints[0],
        min: endpoints[1]
      },
      xAxes: {
        display: false
      },
    },
    plugins: {
      legend: {
        display: false
      }
    },
    elements: {
      line: {
        borderWidth: 1,
        tension: 0.2,
      },
      point: {
        radius: 0,
      }
    },
  })
}