import { Box, Paper, Typography } from "@mui/material";


const ActionPanel = ({ action }) => {
  return (
    <Paper
      style={{
        display: "flex",
        flexDirection: "row",
        width: 200,
        margin: 10,
      }}
      elevation={4}
    >
      <Box>
        <Typography style={{ marginLeft: 10 }}>Current action{Object.keys(action).length > 1 ? 's': ''}:</Typography>
        <br />
        {Object.entries(action).map(([key, val]) => (
          <Typography style={{ marginLeft: 10 }}><b>{ key }</b> { val }</Typography>
        ))}
      </Box>
    </Paper>
  );
};

export default ActionPanel;
