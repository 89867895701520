import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { Box } from "@mui/material";
import MainButton from "../../components/StyledMui/MainButton";


const ButtonLink = ({ linkTo, text }) => {
  return (
    <Link
      to={linkTo}
      style={{
        fontFamily: "Roboto",
        fontWeight: 400,
        marginBottom: 10,
        marginTop: 40,
        textDecoration: "none",
        color: "black"
      }}
    >
      <MainButton
        variant="contained"
        color="primary"
        content={text}
      />
    </Link>
  );
}


export const Profile = ({ authenticated }) => {
  let navigate = useNavigate();
  
  useEffect(() => {
    if (authenticated === false) {
      navigate("/login");
    }
  }, [authenticated, navigate])
  
  return (
    <Box style={{ textAlign: "center"}}>
      <div style={{ marginTop: "20%"}}>
        <ButtonLink linkTo="/change-password" text="Change password" />
      </div>
      <br />
      <div style={{ marginTop: 10 }} >
        <ButtonLink linkTo="/get-guest-invite" text="Get guest invite" />
      </div>
    </Box>
  );
}

Profile.propTypes = {
  authenticated: PropTypes.bool
}