import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import React from "react";
import OilZone from "./OilZone";


const AirSection = ({
      valuesExist, readings, section, plotOptions,
      zoneWidth, classes, zones, trends, zonesInSections
}) => {
  let currentValue = valuesExist ? readings['AITOS_43FIC0' + section][0] : 0
  let plcValue = valuesExist ? readings['air' + section][0] : 0

  return (
    <Grid container spacing={3} direction={section === 1 ? 'row' : 'column'}
          style={{width:zoneWidth, display:"inline-block", margin:'1%', marginBottom: 0}}>
      <Grid container spacing={3} direction='row' justifyContent={'center'}>
        {zones.map(zone => (
          <OilZone zone={zone} readings={readings} classes={classes} trends={trends} plotOptions={plotOptions}
                   zonesInSections={zonesInSections}/>
        ))}
        <Grid item xs={12}>
          <Paper className={classes.paper} elevation={4}>
            <Typography><b>Air Section {section}</b></Typography>
            <Typography>Current value: {currentValue} Nm³</Typography>
            <Typography>PLC value: {plcValue} Nm³</Typography>
          </Paper>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default AirSection;
