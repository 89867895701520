import React, { useEffect, useState } from "react";
import { HubConnectionBuilder } from '@microsoft/signalr';
import { Box, createTheme, ThemeProvider } from "@mui/material";
import StatusOverview from "../components/RealTimeComponents/StatusOverview/StatusOverview";
import ActionPanel from "../components/RealTimeComponents/ActionPanel";
import LivePlot from "../components/RealTimeComponents/LivePlot";
import NewTable from "../components/RealTimeComponents/NewTable";
import NewZones from "../components/RealTimeComponents/Zones/NewZones";
import { Dropdown } from "../components/GenerateGraphComponents";
import { getAllTagsDictionary } from "../utils/getAllTagsDictionary";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { authHeader } from "../services";


const theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#081107",
    },
    background: {
      paper: "#ffffff"
    }
  },
});


export const LiveDashboard = ({ authenticated }) => {
  const [connection, setConnection] = useState();
  const [reading, setReading] = useState({});
  const [trends, setTrends] = useState({});
  const [actions, setActions] = useState({'action': "No actions yet"});
  const [status, setStatus] = useState({
    "Total flue gas": "loading...",
    "Fuel": "loading...",
    "O2": "loading..."
  });
  const [dict, setDict] = useState();
  const [plotTags, setPlotTags] = useState(["AITOS_32FQI02"]);
  const [dropdownValue, setDropdownValue] = useState([
    "AITOS_32FQI02", "AITOS_32QT02", "AITOS_43FQI15", "air", "k_factor", "AITOS_02SIC02", "AITOS_02SQ02",
    "AITOS_47PE03EM1", "AITOS_10TT07", "ncv", "fuel"
  ])
  const [allTags, setAllTags] = useState({});

  let navigate = useNavigate();

  useEffect(() => {
    if (authenticated === false) {
      navigate("/login");
    }
  }, [authenticated, navigate])


  useEffect(() => {
    getAllTagsDictionary()
      .then(data => {
        setDict(data);
      })
      .catch(err => console.error(err));

    const loginToken = JSON.parse(localStorage.getItem("JWT"));

    const newConnection = new HubConnectionBuilder()
      .withUrl("https://api.aitos.no/livedata", { accessTokenFactory: () => loginToken })
      .withAutomaticReconnect()
      .build();

    setConnection(newConnection);
  }, []);


  /*
  useEffect(() => {
    let tags = {};
    let r = Object.assign({}, reading);
    delete r["air1"];
    delete r["air2"];
    delete r["air3"];
    delete r["air4"];
    delete r["air5"];
    delete r["total_flue_gas"];
    for (const [key, val] of Object.entries(r)){
      if (dict) {
        tags[key] = dict[key];
      }
    }
      setAllTags(tags);
  }, [reading])
  */

  useEffect(() => {
    if (connection) {
      connection.start()
        .then(() => {
          console.log('Connected!');

          connection.on("ReceiveLiveActions", function (data) {
            setActions(data);
          });

          connection.on("ReceiveLiveDataStatus", function (data) {
            setStatus(data);
          });

          connection.on('ReceiveLiveTrends', function (data) {
            setTrends(data);
          });

          connection.on('ReceiveLiveData', function (data) {
            console.log(data);
            let tmp = reading;
            setReading({});
            Object.entries(data)
              .map(([key, val]) => {
                if (Object.keys(reading).includes(key)) {
                  tmp[key].unshift(val !== 0 ? val : 0);
                } else {
                  tmp[key] = val !== 0 ? [val] : [0];
                }
                if (tmp[key].length > 24) {
                  tmp[key].pop();
                }
              });
            setReading(tmp);
          });
        })
        .catch((e) => console.log('Connection failed: ', e));
    }
  }, [connection]);


  const handleDropdownChange = (e) => {
    setDropdownValue(e.target.value);
    console.log(allTags);
  };

  const handleOpen = (e) => {
    let tmp = reading;
    let at = {};

    Object.keys(tmp).forEach((key) => {
      at[key] = dict[key];
    })
    setAllTags(at);
  }

  return (
    <ThemeProvider theme={ theme }>
      <Box style={{ width: "100%" }}>
        <Box id="plotanddropdown" style={{float: "left"}}>
          <Box id="liveplot" style={{ paddingLeft: 20, paddingTop: 20, paddingRight: 10 }}>
            <LivePlot data={ [reading, plotTags] } />
          </Box>
          <Box id="dropdownmenu" style={{ paddingLeft: 40, paddingTop: 40 }}>
            <Dropdown
              value={ dropdownValue }
              handleChange={ handleDropdownChange }
              dropdownOptions={ allTags }
              label="Select tags to include in table"
              handleOpen={ handleOpen }
            />
          </Box>

        </Box>
        <Box id="statusandaction" style={{ float: "left", paddingTop: 10 }}>
          <StatusOverview status={ status } />
          <ActionPanel action={ actions } />
        </Box>
        <Box id="table" style={{ float: "left", paddingTop: 20, paddingLeft: 10 }}>
          <NewTable
            reading={ reading }
            setPlot={ setPlotTags }
            plotTags={ plotTags }
            valuesToDisplay={ dropdownValue }
          />
        </Box>

      </Box>

      <Box>
        <NewZones readings={reading} trends={trends}/>
      </Box>
    </ThemeProvider>
  );
};

LiveDashboard.propTypes = {
  authenticated: PropTypes.bool
}