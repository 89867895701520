import { Box } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import MainButton from "../components/StyledMui/MainButton";
import { useEffect } from "react";
import PropTypes from "prop-types";
import { Profile } from "./auth/Profile";


const ButtonLink = ({ linkTo, text }) => {
  return (
    <Link
      to={linkTo}
      style={{
        fontFamily: "Roboto",
        fontWeight: 400,
        marginBottom: 10,
        marginTop: 40,
        textDecoration: "none",
        color: "black"
      }}
    >
      <MainButton
        variant="contained"
        color="primary"
        content={text}
      />
    </Link>
  )
}

export const Home = ({ authenticated }) => {
  let navigate = useNavigate();

  useEffect(() => {
    if (authenticated === false) {
      navigate("/login");
    }
  }, [authenticated, navigate])
  return (
    <Box style={{ textAlign: "center"}}>
      <div style={{ marginTop: "20%"}}>
        <ButtonLink linkTo="/plot" text="Create plot" />
      </div>
      <div style={{ marginTop: 10}}>
        <ButtonLink linkTo="/live" text="View live data" />
      </div>
    </Box>
  )
}

Home.propTypes = {
  authenticated: PropTypes.bool
}