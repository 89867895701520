import { Link } from "react-router-dom";
import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types"


const MyButton = styled(Button)({
  color: "#ffffff",
  borderColor: "#ffffff",
  position: "relative",
  display: "inline-block",
  marginRight: "2vh",
  '&:hover': {
    color: "#000000",
    borderColor: "#000000"
  },
});


const NavbarElement = ({ route, label }) => {

  return (
    <Link to={route} >
      <MyButton variant="outlined">
        { label }
      </MyButton>
    </Link>
  );
}

NavbarElement.propTypes = {
  route: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired
}

export default NavbarElement;