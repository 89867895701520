import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import RemoveIcon from "@mui/icons-material/Remove";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

export function setTrend(trend) {
  if (trend === 1) {
    return <ArrowUpwardIcon fontSize='small' style={{color: '#126811'}}>up</ArrowUpwardIcon>;
  } else if (trend === -1) {
    return <ArrowDownwardIcon fontSize='small' style={{color: '#84261a'}}>down</ArrowDownwardIcon>;
  } else {
    return <RemoveIcon fontSize='small' style={{color: '#cbda2c'}}>stable</RemoveIcon>;
  }
}