import MainButton from "../../components/StyledMui/MainButton";
import { Box, Button, Paper, Snackbar, Typography } from "@mui/material";
import React, { useState } from "react";
import AuthService from "../../services/auth";
import { styled } from "@mui/material/styles";
import app_colors from "../../config/app_colors.json";
import axios from "axios";
import { authHeader } from "../../services";


const MyButton = styled(Button)({
  backgroundColor: app_colors.primary,
  position: "relative",
  display: "inline-block",
  marginRight: "2vh",
  '&:hover': {
    backgroundColor: app_colors.secondary
  },
});

const Div = styled('div')(({ theme }) => ({
  ...theme.typography.button,
  backgroundColor: app_colors.primary,
  padding: theme.spacing(1),
}));

export const GetGuestInvite = () => {
  const [result, setResult] = useState(null);
  const [error, setError] = useState(null);
  const [snackbarOpts, setSnackbarOpts] = useState(false);
  const [vertical, setVertical] = useState("bottom");
  const [horizontal, setHorizontal] = useState("center");


  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpts(false );
  };

  const handleGetInvite = async () => {
    let roles = AuthService.getCurrentUserRoles();
    //console.log(roles)
    let res = "";
    if (roles.includes("Admin") || roles.includes("CreateTmpUser")) {
      await axios({
        method: 'get',
        url: "https://api.aitos.no/accounts/get-guest",
        headers: authHeader()
      })
        .then(function (result) {
          console.log(result.data);
          res = result.data;
        })
        .finally(() => {
          setError(null);
          setResult(res);
        })
    } else {
      setResult(null);
      setError("This user is not authorized to get guest invitations.");
    }
  }


  return (
    <Box style={{ textAlign: "center" }}>
      <div style={{ marginTop: "20%" }} onClick={handleGetInvite}>
        <MyButton
          variant="contained"
          color="primary"
          content="Get invite"
        >
          Get invite
        </MyButton>
      </div>

      <Snackbar
        open={ snackbarOpts }
        autoHideDuration={ 4000 }
        message="Invite copied."
        onClose={ handleSnackbarClose }
        anchorOrigin={{ vertical, horizontal }}
      />

      <br />
      {
        result && (
          <div style={{ marginTop: 10, display: "flex", justifyContent: "center" }} >
            <Paper
              elevation={3}
              sx={{
                padding: 3,
                maxWidth: "30vw"
              }}
              onClick={() => {
                navigator.clipboard.writeText(result);
                setSnackbarOpts(true);
              }}
            >
              <Typography variant="button"
                style={{
                  fontFamily: "Roboto"//, fontSize: "medium"
                }}

              >
                {result}
              </Typography>
            </Paper>

          </div>
        )
      }
      <br />
      {
        /*
        error && (
          <div style={{ marginTop: 10 }} >
            <p style={{
              fontFamily: "Roboto", fontSize: "medium"
            }}>
              {error}
            </p>
          </div>
        )

         */
      }
    </Box>
  );
}