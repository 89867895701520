import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import TextFieldOutlined from "../../components/StyledMui/TextFieldOutlined";
import FormSubmitButton from "../../components/StyledMui/FormSubmitButton";
import AuthService from "../../services/auth";
import { useEffect } from "react";


export const ChangePassword = ({ authenticated, handleLogOut }) => {
  let navigate = useNavigate();

  useEffect(() => {
    if (authenticated === false) {
      navigate("/login");
    }
  }, [authenticated, navigate])

  const { register, control, handleSubmit, watch, setError, formState: { errors } } = useForm({
    defaultValues: {
      currentPassword: "",
      newPassword: "",
      confirmNewPassword: ""
    }
  });


  const onSubmit = (data, e) => {

    AuthService.changePassword(data.currentPassword, data.newPassword, data.confirmNewPassword)
      .then(() => {
        handleLogOut();
        navigate("/login");
      })
      .catch((err) => {
        console.error(err);
      });
  }

  const onError = (errors, e) => {
    console.log(errors);
  }

  return (
    <div style={{ textAlign: "center" }}>
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <Controller
          render={({ field }) => (
            <TextFieldOutlined
              label="Current password"
              placeholder="Current password"
              type="password"
              rfhOptional={{ ...field }}
              style={{ marginTop: "20vh" }}
            />
          )}
          name="currentPassword"
          control={ control }
          rules={{
            required: {
              value: true,
              message: "Current password required"
            },
          }}
        />

        <br />

        <Controller
          render={({ field }) => (
            <TextFieldOutlined
              label="New password"
              placeholder="New password"
              type="password"
              rfhOptional={{ ...field }}
              style={{ marginTop: "4vh" }}
            />
          )}
          name="newPassword"
          control={ control }
          rules={{
            minLength: {
              value: 6,
              message: "Minimum 6 characters."
            },
            required: {
              value: true,
              message: "New password required."
            },
          }}
        />

        <br />

        <Controller
          render={({ field }) => (
            <TextFieldOutlined
              label="Confirm new password"
              placeholder="Confirm new password"
              type="password"
              rfhOptional={{ ...field }}
              style={{ marginTop: "4vh" }}
            />
          )}
          name="confirmNewPassword"
          control={ control }
          rules={{
            minLength: {
              value: 6,
              message: "Minimum 6 characters."
            },
            required: {
              value: true,
              message: "confirm new password required."
            },
          }}
        />
        <br />
        <FormSubmitButton content="Change password" style={{ marginTop: "4vh"}} />
      </form>
    </div>
  );
}

ChangePassword.propTypes = {
  authenticated: PropTypes.bool.isRequired,
  handleLogOut: PropTypes.func.isRequired
}