import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom"
import { createTheme, ThemeProvider } from "@mui/material";
import { NavBar } from "./components/NavBarComponents";
import { Home, LiveDashboard, GenerateGraph, Login, Profile, ChangePassword, GetGuestInvite } from "./pages";
import "./index.css";

import AuthService from "./services/auth"


const theme = createTheme();

function App() {
  const [authenticated, setAuthenticated] = useState(localStorage.getItem('JWT') !== null);

  const handleLogOut = () => {
    AuthService.logout();
    setAuthenticated(false)
  }

  const handleLogIn = () => {
    setAuthenticated(true);
  }

  useEffect(() => {
    // eslint-disable-next-line no-restricted-globals
    addEventListener("storage", (e) => {
      setAuthenticated(!authenticated);
    });
  }, [authenticated])

  return (
    <ThemeProvider theme={theme}>
      <NavBar authenticated={authenticated} handleLogOut={handleLogOut}>
        <Routes>
          <Route path="/" element={ <Home authenticated={authenticated} /> } />
          <Route path="/live" element={ <LiveDashboard authenticated={authenticated} /> } />
          <Route path="/plot" element={ <GenerateGraph authenticated={authenticated}/> } />
          <Route path="/login" element={ <Login handleLogIn={handleLogIn} /> } />
          <Route path="/profile" element={ <Profile authenticated={authenticated} /> } />
          <Route path="/change-password" element={ <ChangePassword handleLogOut={handleLogOut} authenticated={authenticated} /> } />
          <Route path="/get-guest-invite" element={ <GetGuestInvite /> } />
        </Routes>
      </NavBar>
    </ThemeProvider>
  );
}
export default App;

