import { Box, Button } from "@mui/material";
import NavbarElement from "./NavbarElement";
import React, { useEffect, useState } from "react";
import app_colors from "./../../config/app_colors.json"
import { styled } from "@mui/material/styles";

import AuthService from "../../services/auth";
import PropTypes from "prop-types";


const MyButton = styled(Button)({
  color: "#ffffff",
  borderColor: "#ffffff",
  position: "relative",
  display: "inline-block",
  marginRight: "2vh",
  '&:hover': {
    color: "#000000",
    borderColor: "#000000"
  },
});

const leftLinks = [
  <NavbarElement route="/" label="Home" />,
  <NavbarElement route="/live" label="Live view" />,
  <NavbarElement route="/plot" label="Plot" />
];

const rightLinks = [
  <NavbarElement route="/login" label="Login" />
];


export const NavBar = ({ authenticated, children, handleLogOut }) => {

  const rightAuth = [
    <NavbarElement route="/profile" label="Profile" />,
    <MyButton variant="outlined" onClick={handleLogOut} children="Log out"  />
  ]

  return (
    <>
      <Box
        style={{
          overflow: "hidden",
          height: "6vh",
          backgroundColor: app_colors.primary,
          zIndex: 12,
          width: "100%"
        }}
      >
        <Box
          style={{
            marginLeft: "15%",
            float: "left",
            width: "35%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "start"
          }}
        >
          {
            leftLinks.map((e) => {
              return e;
            })
          }
        </Box>

          <Box
            style={{
              width: "35%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "end"
            }}
          >
            {
              authenticated === true
                ? (
                  rightAuth.map((e) => {
                    return e;
                  })
                )
                : (
                  rightLinks.map((e) => {
                    return e;
                  })
                )
            }
          </Box>
      </Box>

      {children}
    </>
  )
}

NavBar.propTypes = {
  authenticated: PropTypes.bool.isRequired
}

//export default NavBar;

