export const FURNACES = [
  {
    fullName: "Forus",
    urlName: "forus1"
  },
  {
    fullName: "Milton Keynes 1",
    urlName: "mk1"
  },
  {
    fullName: "Milton Keynes 2",
    urlName: "mk2"
  },
];