import { Button } from "@mui/material";
import LaunchIcon from "@mui/icons-material/Launch";
import GetAppIcon from "@mui/icons-material/GetApp";


export const Result = ({ filename }) => {
  return (
    <div
      style={{
        marginTop: "50px",
      }}
    >
      <Button
        variant="contained"
        color="primary"
        href={`https://aitos.no/files/plot/${filename}.html`}
        target="_blank"
        endIcon={<LaunchIcon />}
      >
        Open plot
      </Button>

      <br />

      <Button
        variant="contained"
        color="primary"
        href={`https://aitos.no/files/excel/${filename}.xlsx`}
        target="_blank"
        style={{
          marginTop: "20px",
          backgroundColor: "#107c41",
        }}
        disabled
        endIcon={<GetAppIcon />}
      >
        Download Excel document
      </Button>
    </div>
  );
};
