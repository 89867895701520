import axios from "axios";
import config from "../config/config.json"
import { authHeader } from "./authHeader";

class AuthService {
  login(username, password) {
    return axios
      .post(config.API_URL.MAIN + config.API_URL.LOGIN_SUFFIX, {
        username,
        password
      })
      .then(res => {
        //console.log(res);
        if (res.data.Token) {
          localStorage.setItem("JWT", JSON.stringify(res.data.Token));
          localStorage.setItem("username", JSON.stringify(res.data.Username));
          localStorage.setItem("roles", JSON.stringify(res.data.Roles));
        }
        return res.data;
      });
  }

  changePassword(currentPassword, newPassword, confirmNewPassword) {
    let username = this.getCurrentUser();
    if (username.length === 0){
      return "Not logged in.";
    }

    return axios
      .post(config.API_URL.MAIN + config.API_URL.CHANGE_PASSWORD_SUFFIX, {
        username: username,
        currentPassword: currentPassword,
        newPassword: newPassword,
        confirmNewPassword: confirmNewPassword
      }, {
        headers: authHeader()
      })
      .then(res => {
        this.logout();
        return res.data.status;
      });
  }

  getGuestInvite() {
    let result = "";
    axios({
      method: 'get',
      url: "https://api.aitos.no/accounts/get-guest",
      headers: authHeader()
    })
      .then(function (res) {
        //console.log(res.data);
        result = res.data.toString();
      }).finally(() => {
        return result;
      })
      /*.catch(function (err) {
        console.error(err);
        return err;
      });
      */

  }

  logout() {
    localStorage.removeItem("JWT");
    localStorage.removeItem("username");
    localStorage.removeItem("roles");
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem("username"));
  }
  getCurrentUserRoles() {
    return JSON.parse(localStorage.getItem("roles"))
  }
}

export default new AuthService();