import { Paper, Typography } from "@mui/material";


const StatusBox = ({name, status}) => {
  return (
    <>
      <Paper
        style={{
          display: "flex",
          flexDirection: "row",
          width: 250,
          margin: 10,
        }}
        elevation={ 4 }
      >
        <Typography style={{ marginLeft: 10 }}>{ name }:&nbsp;</Typography>
        <Typography>{ status }</Typography>
      </Paper>
    </>
  );
};

export default StatusBox;