import { styled } from "@mui/material/styles";
import { Button } from "@mui/material";
import app_colors from "../../config/app_colors.json";
import PropTypes from "prop-types";


const MyButton = styled(Button)({
  backgroundColor: app_colors.primary,
  position: "relative",
  display: "inline-block",
  '&:hover': {
    backgroundColor: app_colors.secondary
  },
});

const FormSubmitButton = ({ content, style }) => {
  return (
    <MyButton
      variant="contained"
      children={ content }
      type="submit"
      style={{ ...style }}
    />
  );
}

export default FormSubmitButton;

FormSubmitButton.propTypes = {
  content: PropTypes.string.isRequired,
  style: PropTypes.any
}