import { styled } from "@mui/material/styles";
import { TextField } from "@mui/material";
import app_colors from "../../config/app_colors.json";
import PropTypes from "prop-types";


const MyTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: app_colors.secondary,
  },
  '&data-shrink="true"': {
    color: app_colors.secondary
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: app_colors.primary,
      borderWidth: 2
    },
    '&:hover fieldset': {
      borderColor: app_colors.secondary,
    },
    '&.Mui-focused fieldset': {
      borderColor: app_colors.secondary,
    },
  }
});


const TextFieldOutlined = ({ label, type, placeholder, rfhOptional, style }) => {
  return (
    <MyTextField
      placeholder={ placeholder }
      variant="outlined"
      style={{ ...style }}
      label={ label }
      type={ type }
      {...rfhOptional}
    />
  );
}

export default TextFieldOutlined;

TextFieldOutlined.propTypes = {
  label: PropTypes.string.isRequired,
  type: PropTypes.oneOf(["text", "password"]),
  placeholder: PropTypes.string,
  rfhOptional: PropTypes.any,
  style: PropTypes.any
}

TextFieldOutlined.defaultProps = {
  type: "text",
  placeholder: ""
}