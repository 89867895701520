import { range } from "../../components/RealTimeComponents/Functions";

export function getData(section, trends, readings) {
  let tag = section < 10 ? 'AITOS_10TT0' + section : 'AITOS_10TT' + section
  let color = ['#c55', '#55c', '#5c5'][trends[tag] + 1]
  let data = readings[tag].slice(0, 12).reverse()
  return {
    labels: range(readings[tag].slice(0, 12).length),
    datasets: [
      {
        data: data,
        borderColor: color,
      }
    ],
  };
}
