import {
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import React, { ReactNode } from "react";
import PropTypes from "prop-types";



export const Dropdown = ({ value, dropdownOptions, handleChange, label = "Verdier", handleOpen }) => {

  return (
    <FormControl style={{ minWidth: 400, maxWidth: 600 }}>
      <InputLabel id="value-select">{label}</InputLabel>
      <Select
        labelId="value-select"
        multiple
        value={ value }
        onChange={ handleChange }
        input={ <Input /> }
        onOpen={ handleOpen }
      >
        {
          Object.entries(dropdownOptions).map(([key, val]) => {
            return <MenuItem id={ key } value={ key }>{ val }</MenuItem>
        })
        }
      </Select>
    </FormControl>
  );
};


Dropdown.propTypes = {
  value: PropTypes.array,
  dropdownOptions: PropTypes.object,
  handleChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  handleOpen: PropTypes.func.isRequired
}