import React, { useEffect, useState } from "react";
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import { TableCell, TableHead, TableRow, Tooltip } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import FormTitleText from "../FormTitleText";
import { makeStyles } from "@mui/styles";
import {getAllTagsDictionary} from "../../utils/getAllTagsDictionary";


const useStyles = makeStyles({
  table: {
    width: 700,

  },
});


const NewTable = ({ reading, setPlot, plotTags, valuesToDisplay }) => {
  const classes = useStyles();

  const [ displayNames, setDisplayNames ] = useState({});
  const [tableColumns, setTableColumns] = useState(6); // Not including name column
  const [ titles, setTitles ] = useState(["Name", "1 (newest)", "2", "3", "4", "5", "6"]);

  useEffect(() => {
    getAllTagsDictionary()
      .then(data => {
        setDisplayNames(data);
      })
      .catch(err => console.error(err));
  }, []);

  const togglePlot = (key) => {
    if (plotTags.includes(key)) {
      let x = plotTags.slice(0, plotTags.indexOf(key)).concat(plotTags.slice(plotTags.indexOf(key) + 1));
      setPlot(x);
    }
    else {
      setPlot(plotTags.concat([key]));
    }
  }


  if (Object.values(reading).length === 0 || Object.values(reading)[0].length === 0) {
    return (
      <>
        <FormTitleText>Table loading...</FormTitleText>
      </>
    );
  }

  return (
    <TableContainer component={Paper} style={{width:'100%'}}>
      <Table className={classes.table} size="small">
        <TableHead>
          <TableRow>
            {titles.map(title => <TableCell>{title}</TableCell>)}
          </TableRow>
        </TableHead>
        <TableBody>
          {
            valuesToDisplay.map(key => {
              return (
                <Tooltip title={key} arrow placement="left">
                  <TableRow
                    key={key}
                    onClick={() => togglePlot(key)}
                    style={{cursor: 'pointer'}}
                    selected={plotTags.includes(key)}
                  >
                    <TableCell component="th" scope="row">
                      { displayNames[key] }
                    </TableCell>
                    {
                      reading[key].slice(0, tableColumns).map((value) => {
                        return <TableCell align="right">{value}</TableCell>})
                    }
                  </TableRow>
                </Tooltip>
              )
            })
          }
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default NewTable;