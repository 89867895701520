import axios from "axios";
import { authHeader } from "./authHeader";
import config from "../config/config.json"
import format from "date-fns/format";

class UserService {
  getUsername() {
    return axios.get(config.API_URL.MAIN + config.API_URL.USERNAME_SUFFIX, { headers: authHeader() });
  }

  async getGraph(fromDate, toDate, values, title, furnace) {

    if (values.length === 0) {
      return "Error: No values selected.";
    }

    let valuesCSV = values.toString();
    title = title.trim();
    furnace = furnace.toLowerCase()
    let localTime = furnace === "forus1";

    let result = "";

    const URL = `https://api.aitos.no/forus1/create-plot`;
    await axios({
      method: 'get',
      url: URL,
      headers: authHeader(),
      params: {
        fromDate: format(new Date(fromDate), "yyyy/MM/dd HH:mm"),
        toDate: format(new Date(toDate), "yyyy/MM/dd HH:mm"),
        values: valuesCSV,
        title: title,
        furnace: furnace,
        localTime: localTime
      },
    })
      .then(function (res) {
        console.log(res.data);
        result = res.data;
        return result;
      })
      .catch(function (err) {
        console.error(err);
        return "Error: Can't connect to plotting server.";
      });

    return result;
  }

  isLoggedIn() {
    return axios.get(config.API_URL.MAIN + config.API_URL.ISLOGGEDIN_SUFFIX, { headers: authHeader() });
  }

}

export default new UserService();